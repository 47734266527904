import React from 'react';
import GridList from '../grid-list/GridList';

export default function Item(item: any) {
  return (
    <div className={'content-block dx-card responsive-paddings'}>
      {GridList(item.imgs)}
      <div><a href={item.url} target="_blank">{item.title}</a></div>
      <div>{item.description}</div>
    </div>
  );
}
