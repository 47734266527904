import React from 'react';
import Item from '../item/Item';
import LoadContent from '../load-content/LoadContent';
import {getDataByPath} from "../../api/api";
import {useAuth} from "../../contexts/auth";

export default function Gallery(name: string, path: string) {
  const auth = useAuth();
  const [isLoadingData, setisLoadingData] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [showData, setShowData] = React.useState(false);

  function loadData() {
    setisLoadingData(true);
    setShowData(true)
    getDataByPath(path, auth).then((json) => {
      setData(json);
    }).catch(e => {
      setShowData(false);
    }).finally(() => {
      setisLoadingData(false);
    })
  }

  React.useEffect(() => {
    loadData()
  }, [path]);

  const {lastUpdated} = data as any
  let {data: jsonData} = data as any
  if (!jsonData) {
    jsonData = []
  }

  return (
    <>
      {LoadContent((
        <>
          <h2 className={'content-block'}>{name}</h2>
          {showData && !isLoadingData ? (<>
            <h4 className={'content-block'}>{lastUpdated}</h4>
            {jsonData.map((item: any) => Item(item))}
          </>) : <></>}
        </>
      ), isLoadingData, setisLoadingData)}
    </>
  );
}
