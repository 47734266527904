import {getToken} from "./token";
import {get, post} from "./request";

export function login(email: string, password: string) {
  return post(`${getBaseUrl()}/auth/login`, { email: email, password: password })
}

export function getXVideosProfiles(auth: any) {
  return authGet(`/xvideos/profiles`, auth)
}

export function getXVideosPlaylistData(playlist: any, auth: any) {
  return updateLastUpdatedFromJson(authGet(`/xvideos/${playlist}`, auth))
}

export function getXVideosTagData(tag: any, auth: any) {
  return updateLastUpdatedFromJson(authGet(`/xvideos/tag/${tag}`, auth))
}

export function getXVideosProfileData(profile: any, auth: any) {
  return updateLastUpdatedFromJson(authGet(`/xvideos/profile/${profile}`, auth))
}

export function getInstagramProfileData(username: any, auth: any) {
  if (username == 'christina.bertevello') {
    username = 'christina_bertevello';
  }
  return updateLastUpdatedFromJson(authGet(`/instagram/${username}`, auth))
}

export function getDataByPath(path: any, auth: any) {
  return updateLastUpdatedFromJson(authGet(`/${path}`, auth))
}

function updateLastUpdatedFromJson(response: Promise<any>): Promise<any> {
  return response.then((json) => {
    const dt = new Date(json.lastUpdated)
    json.lastUpdated = dt.toLocaleString()
    return json
  })
}

function authGet(url: string, auth: any = null) {
  let response = get(`${getBaseUrl()}${url}`, {
    "Authorization": `Bearer ${getToken()}`
  })
  if (!!auth) {
    response = response.catch((e: any) => {
      const {status} = e
      if (status === 401) {
        auth.signOut();
      }
      throw e
    })
  }
  return response
}

function getBaseUrl() {
  return "https://us-central1-apiprivate.cloudfunctions.net/api";
  // return "http://localhost:3001";
}
