import {getItemFromStorage, removeItemFromStorage, setItemInStorage} from "./storage";

export function setToken(userToken: string) {
  setItemInStorage('token', userToken);
}

export function getToken(): any {
  return getItemFromStorage('token')
}

export function removeToken(): any {
  removeItemFromStorage('token');
}
