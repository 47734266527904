import React from 'react';
import {useParams} from 'react-router';
import Grid from '../../components/grid/Grid';
import {LazyLoadImage} from "react-lazy-load-image-component";
import './Xvideos.scss';
import PlaceholderImage from "../../assets/img/preloader.gif";
import LoadContent from "../../components/load-content/LoadContent";
import {getXVideosPlaylistData, getXVideosProfileData, getXVideosTagData} from "../../api/api";
import {useAuth} from "../../contexts/auth";

export default function Xvideos() {
  let {playlist, profile, tag} = useParams();

  const auth = useAuth();
  const [isLoadingData, setisLoadingData] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [showData, setShowData] = React.useState(false);
  const {data: jsonData, lastUpdated} = data as any

  function dataBody() {
    return (<>
      <h4 className={'content-block'}>{lastUpdated}</h4>
      <div className={'content-block'} style={{
        marginLeft: '30px',
        marginRight: '30px'
      }}>{Grid(5, jsonData.map((item: any) => gridItem(item)))}</div>
    </>)
  }

  function gridItem(item: any) {
    return (<div className={'xvideos-content-block dx-card'}>
      <LazyLoadImage wrapperClassName={'item-img-wrapper-lazy-load-image'} className={'item-img'}
                     src={item.imgs[0]}
                     placeholderSrc={PlaceholderImage}/>
      <div style={{padding: '20px'}}><a href={item.url} target="_blank">{item.title}</a></div>
    </div>)
  }

  function getParamType(playlist: string | null | undefined, profile: string | null | undefined, tag: string | null | undefined) {
    if (!!playlist && playlist !== '') {
      return 'playlist'
    }
    else if (!!profile && profile !== '') {
      return 'profile'
    }
    else if (!!tag && tag !== '') {
      return 'tag'
    }
    else {
      return null
    }
  }

  function getXVideosData(playlist: string | null | undefined, profile: string | null | undefined, tag: string | null | undefined, auth: any) {
    const type = getParamType(playlist, profile, tag);
    if (type === 'playlist') {
      return getXVideosPlaylistData(playlist, auth)
    }
    else if (type === 'tag') {
      return getXVideosTagData(tag, auth)
    }
    else {
      return getXVideosProfileData(profile, auth)
    }
  }

  function loadData() {
    setisLoadingData(true);
    setShowData(true)
    getXVideosData(playlist, profile, tag, auth).then((json) => {
      setData(json);
    }).catch(e => {
      setShowData(false);
    }).finally(() => {
      setisLoadingData(false);
    })
  }

  React.useEffect(() => {
    loadData()
  }, [playlist, profile, tag]);

  function getTitle(playlist: string | null | undefined, profile: string | null | undefined, tag: string | null | undefined) {
    const type = getParamType(playlist, profile, tag);
    if (type === 'playlist') {
      return playlist
    }
    else if (type === 'tag') {
      return tag
    }
    else {
      return profile
    }
  }

  return (
    <>
      {LoadContent((
        <>
          <h2 className={'content-block'}>{getTitle(playlist, profile, tag)}</h2>
          {showData && !isLoadingData ? dataBody() : <></>}
        </>
      ), isLoadingData, setisLoadingData)}
    </>
  );
}
