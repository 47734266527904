import {LazyLoadImage} from "react-lazy-load-image-component";
import './GridList.scss';
import PlaceholderImage from "../../assets/img/preloader.gif";
import Grid from "../grid/Grid";

export default function GridList(imgs: any[]) {
  if (!imgs) { return (<></>) }
  return (
    Grid(3, imgs.map((img, i) => (<div className={'item-img-div'}>
      <LazyLoadImage wrapperClassName={'item-img-wrapper-lazy-load-image'} className={'item-img'} src={img}
                     placeholderSrc={PlaceholderImage}/>
    </div>)))
  )
}
