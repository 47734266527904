import defaultUser from '../utils/default-user';
import {setToken} from "./token";
import {login} from "./api";
import {getItemFromStorage, setItemInStorage} from "./storage";

export async function signIn(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    const {token} = await login(email, password);
    setToken(token);
    setItemInStorage('email', email);

    return {
      isOk: true,
      data: { ...defaultUser, email }
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: { ...defaultUser, email: getItemFromStorage('email') }
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
