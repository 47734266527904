import {Page} from "./pages";
import { withNavigationWatcher } from './contexts/navigation';
import Instagram from './pages/instagram/Instagram';
import Xvideos from './pages/xvideos/Xvideos';

const routes = [
    {
      path: '/escort/:name',
      element: Page
    },
    {
      path: '/doll/:name',
      element: Page
    },
    {
      path: '/games/:name',
      element: Page
    },
    {
      path: '/instagram/:username',
      element: Instagram
    },
    {
      path: '/xvideos/:playlist',
      element: Xvideos
    },
    {
      path: '/xvideos/profile/:profile',
      element: Xvideos
    },
    {
      path: '/xvideos/tag/:tag',
      element: Xvideos
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
