export const navigation = [
  {
    text: 'XVideos',
    icon: 'folder',
    items: [
      {
        text: 'Preferiti 2',
        path: '/xvideos/preferiti_2'
      },
      {
        text: 'Preferiti',
        path: '/xvideos/preferiti'
      },
      {
        text: 'Nura',
        path: '/xvideos/nura'
      },
      {
        text: 'Kadence',
        path: '/xvideos/kadence'
      },
      {
        text: '3d',
        path: '/xvideos/tred'
      },
      {
        text: 'Sex Doll',
        path: '/xvideos/sex_doll'
      },
      {
        text: 'Asian',
        path: '/xvideos/asian'
      },
      {
        text: 'Black Diamond',
        path: '/xvideos/black_diamond'
      },

      {
        text: 'Robot',
        path: '/xvideos/robot'
      },
      {
        text: 'Time',
        path: '/xvideos/time'
      },
      {
        text: 'Lupe',
        path: '/xvideos/lupe'
      },
      {
        text: 'Freeuse',
        path: '/xvideos/freeuse'
      },
      {
        text: 'Movie',
        path: '/xvideos/movie'
      }
    ]
  },
  {
    text: 'XVideos Profiles',
    icon: 'folder',
    items: [
    ]
  },
  {
    text: 'XVideos Tags',
    icon: 'folder',
    items: [
      {
        text: 'aidra-fox',
        path: '/xvideos/tag/aidra-fox'
      }
    ]
  },
  {
    text: 'Escort',
    icon: 'folder',
    items: [
      {
        text: 'EscortForumIt',
        path: '/escort/escortforumit'
      },
      {
        text: 'BakecaIncontrii',
        path: '/escort/bakecaincontrii'
      }
    ]
  },
  {
    text: 'Instagram',
    icon: 'folder',
    items: [
      {
        text: '007natalee',
        path: '/instagram/007natalee'
      },
      {
        text: 'lee.nata.007',
        path: '/instagram/lee_nata_007'
      },
      {
        text: 'natalee.007',
        path: '/instagram/natalee_007'
      },
      {
        text: 'natalee_muse',
        path: '/instagram/natalee_muse'
      },
      {
        text: '007.nata.lee',
        path: '/instagram/007_nata_lee'
      },
      {
        text: 'natalee.007.fit',
        path: '/instagram/natalee_007_fit'
      },
      {
        text: 'svetabily',
        path: '/instagram/svetabily'
      },
      {
        text: 'alexisren',
        path: '/instagram/alexisren'
      },
      {
        text: 'angelsvsdaily',
        path: '/instagram/angelsvsdaily'
      },
      {
        text: 'vsangels_models_',
        path: '/instagram/vsangels_models_'
      },
      {
        text: 'taylor_hill',
        path: '/instagram/taylor_hill'
      },
      {
        text: 'lorena',
        path: '/instagram/lorena'
      },
      {
        text: 'mirandakerr',
        path: '/instagram/mirandakerr'
      },
      {
        text: 'haevyeva',
        path: '/instagram/haevyeva'
      },
      {
        text: 'mollymarcante',
        path: '/instagram/mollymarcante'
      },
      {
        text: 'christina.bertevello',
        path: '/instagram/christina.bertevello'
      },
      {
        text: 'georginamazzeo',
        path: '/instagram/georginamazzeo'
      },
      {
        text: 'victoriassecret',
        path: '/instagram/victoriassecret'
      },
      {
        text: 'lovinlima',
        path: '/instagram/lovinlima'
      },
      {
        text: 'laisribeiro',
        path: '/instagram/laisribeiro'
      }
    ]
  },
  {
    text: 'Doll',
    icon: 'folder',
    items: [
      {
        text: 'Racyme',
        path: '/doll/racyme'
      },
      {
        text: 'Kikdolls',
        path: '/doll/kikdolls'
      },
      {
        text: 'Reallovesexdolls',
        path: '/doll/reallovesexdolls'
      },
      {
        text: 'Sexdolls',
        path: '/doll/sexdolls'
      },
      {
        text: 'Sexysexdoll',
        path: '/doll/sexysexdoll'
      }
    ]
  },
  {
    text: 'Games',
    icon: 'folder',
    items: [
      {
        text: 'FapNation',
        path: '/games/fapnation'
      }
    ]
  }
];
