import {LoadPanel} from 'devextreme-react/load-panel';

export default function LoadContent(body: any, isLoadingData: any, setisLoadingData: any) {
  function hideLoadPanel() {
    setisLoadingData(false);
  }

  return (
    <div>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{of: '.content'}}
        onHiding={hideLoadPanel}
        visible={isLoadingData}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      {body}
    </div>
  );
}
