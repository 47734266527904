const _ = require('lodash');

export default function Grid(nCols: number, items: any) {
  const style = {
    'display': 'grid',
    gridTemplateColumns: _.times(nCols, () => '1fr').join(' ')
  }
  return (
    <div style={style}>
      {items}
    </div>
  )
}
