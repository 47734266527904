import React from 'react';
import Gallery from '../../components/gallery/Gallery';
import { useLocation } from 'react-router-dom';
import route_values from './route-values.json';

export default function Page() {
  const location = useLocation();
  const url = location.pathname;
  const routeValues: any = route_values;
  const {title: titleRoute, path: pathRoute} = routeValues[url];

  return (
    <React.Fragment>
      {Gallery(titleRoute, pathRoute)}
    </React.Fragment>
)}
