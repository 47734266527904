export function setItemInStorage(key: any, value: any) {
  localStorage.setItem(key, value);
}

export function getItemFromStorage(key: any): any {
  return localStorage.getItem(key);
}

export function removeItemFromStorage(key: any): any {
  localStorage.removeItem(key);
}
