import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useScreenSize } from '../../utils/media-query';
import './SideNavigationMenu.scss';
import type { SideNavigationMenuProps } from '../../types';

import * as events from 'devextreme/events';
import {useAuth} from "../../contexts/auth";
import {getXVideosProfiles} from "../../api/api";
import {useLocation} from "react-router-dom";

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;
  const [isLoadingData, setisLoadingData] = React.useState(false);
  const [data, setData] = React.useState({} as any);
  const [showData, setShowData] = React.useState(false);

  const auth = useAuth();
  const { isLarge } = useScreenSize();
  function normalizePath () {
    return navigation.map((item: any) => (
      { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
    ))
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { pathname: currentPath } = useLocation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const getWrapperRef = useCallback((element: HTMLDivElement) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e: React.PointerEvent) => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode, data]);

  function loadData() {
    setisLoadingData(true);
    setShowData(true)
    getXVideosProfiles(auth)
      .then((profiles) => {
        setisLoadingData(false);
        let menu = navigation.map((item: any) => (
          { ...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
        ))
        const index = menu.map(i => i.text.toLowerCase()).indexOf('XVideos Profiles'.toLowerCase());
        menu[index].items = profiles.map((profile: string) => {
          return {text: profile, path: `/xvideos/profile/${encodeURI(profile)}`}
        })
        menu[index].expanded = false
        setData(menu)
      })
      .catch((error) => console.log(error));
  }

  React.useEffect(() => {
    loadData()
  }, []);

  function getTreeView(items: any) {
    return (
      <div
        className={'dx-swatch-additional side-navigation-menu'}
        ref={getWrapperRef}
      >
        {children}
        <div className={'menu-container'}>
          <TreeView
            ref={treeViewRef}
            items={items}
            keyExpr={'path'}
            selectionMode={'single'}
            focusStateEnabled={false}
            expandEvent={'click'}
            onItemClick={selectedItemChanged}
            onContentReady={onMenuReady}
            width={'100%'}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {showData && !isLoadingData ? getTreeView(data) : getTreeView(items)}
    </>
  );
}
