import React from 'react';
import {useParams} from 'react-router';
import GridList from '../../components/grid-list/GridList';
import LoadContent from '../../components/load-content/LoadContent';
import {getInstagramProfileData} from "../../api/api";
import {useAuth} from "../../contexts/auth";

export default function Instagram() {
  let {username} = useParams();

  const auth = useAuth();
  const [isLoadingData, setisLoadingData] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [showData, setShowData] = React.useState(false);

  function loadData() {
    setisLoadingData(true);
    setShowData(true)
    getInstagramProfileData(username, auth).then((json) => {
      setData(json);
    }).catch(e => {
      setShowData(false);
    }).finally(() => {
      setisLoadingData(false);
    })
  }

  React.useEffect(() => {
    loadData()
  }, [username]);

  const {data: jsonData, lastUpdated} = data as any
  return (
    <>
      {LoadContent((
        <>
          <h2 className={'content-block'}><a href={`https://www.instagram.com/${username}/`} target="_blank">{username}</a></h2>
          {showData && !isLoadingData ? (<>
            <h4 className={'content-block'}>{lastUpdated}</h4>
            <div className={'content-block dx-card responsive-paddings'}>
              {GridList(jsonData)}
            </div>
          </>) : <></>}
        </>
      ), isLoadingData, setisLoadingData)}
    </>
  );
}
