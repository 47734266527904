import {HttpRequestError} from "../classes/HttpRequestError";

export function get(url: string, headers: any = {}) {
  let init: any = {};
  if (!!headers) {
    init.headers = headers
  }
  return handleFetch(fetch(url, init));
}

export function post(url: any, data: any, headers: any = {}) {
  let init: any = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (!!headers) {
    init.headers = {...init.headers, ...headers}
  }

  return handleFetch(fetch(url, init));
}

function handleFetch(fetch: Promise<any>) {
  return fetch.then((response) => {
    console.log(response)

    if (response.ok) {
      return response.json();
    }

    // TODO Finish
    throw new HttpRequestError(response.status, 'Something went wrong');
  })
    .catch((error) => {
      console.log(error)
      throw error;
    });
}
